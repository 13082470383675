import { combineReducers } from "@reduxjs/toolkit";
import configReducer from './slices/configSlice';
import userReducer from './slices/userSlice';

const rootReducer = combineReducers({
    configReducer,
    userReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;