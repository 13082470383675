import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlusCircle,
  faMinusCircle,
  faTrashAlt,
  faCheckCircle,
  faTimesCircle,
  faEdit,
  faInfoCircle,
  faArrowCircleRight,
  faFilePdf,
  faDownload,
  faPaperPlane,
  faExclamationTriangle,
  faSearch,
  faComment,
  faUndo,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";

export const initializeIcons = () => {
  library.add(
    faPlusCircle,
    faMinusCircle,
    faTrashAlt,
    faCheckCircle,
    faTimesCircle,
    faEdit,
    faInfoCircle,
    faArrowCircleRight,
    faFilePdf,
    faDownload,
    faPaperPlane,
    faExclamationTriangle,
    faSearch,
    faComment,
    faUndo,
    faFileExcel
  );
};
