import React, { useState, CSSProperties } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";

const Header = () => {
  const dropDownMenuItemStyle: CSSProperties = {
    color: "black",
  };

  const headerTextStyle: CSSProperties = {
    color: "white",
    padding: "10px",
  };

  const brandStyle: CSSProperties = {
    paddingRight: 20,
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );

  const toggle = () => setIsExpanded(!isExpanded);
  const isImpersonating = user && user.srsId !== user.actualUserSrsId;

  const getLoginOrSignOutLink = () => {
    const loginUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/login`;
    const logoutUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/logout`;

    return isAuthenticated ? (
      <a
        className="nav-link"
        title="Sign Out"
        href={logoutUrl}
        style={headerTextStyle}
      >
        Sign Out
      </a>
    ) : (
      <a
        className="nav-link"
        title="Log In"
        href={loginUrl}
        style={headerTextStyle}
      >
        Log In
      </a>
    );
  };

  const getLoggedInUserDisplay = () => {
    return isAuthenticated ? (
      <>
        <div className="d-none d-xl-block" style={headerTextStyle}>
          {isImpersonating ? (
            <span
              className="mx-2"
              title={`Impersonating: ${user!.displayName}`}
            >
              Impersonating: {user!.displayName}
            </span>
          ) : (
            <span className="mx-2" title={`Welcome: ${user!.displayName}`}>
              Welcome: {user!.displayName}
            </span>
          )}
        </div>
      </>
    ) : (
      ""
    );
  };

  const getHelpContactUsOptions = () => {
    return (
      <>
        <NavItem>
          <a
            title="Help"
            className="nav-link"
            target="_blank"
            rel="noopener noreferrer"
            href="http://fdotsp.dot.state.fl.us/sites/TransportationSupport/OrgDev/PPM/SitePages/Home.aspx"
          >
            Help
          </a>
        </NavItem>
        <NavItem>
          <NavLink to="/ContactUs" tag={Link} title="Contact Us">
            Contact Us
          </NavLink>
        </NavItem>
      </>
    );
  };

  const getUnAuthenticatedOptions = () => {
    return (
      <>
        <NavItem>
          <NavLink to="/Forms" tag={Link} title="Forms">
            Forms
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Procedures" tag={Link} title="Procedures">
            Procedures
          </NavLink>
        </NavItem>
      </>
    );
  };

  const getAuthenticatedOptions = () => {
    if (user !== undefined && isAuthenticated) {
      return (
        <>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Reviews
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/Reviews/ScheduledReviewMonths"}
              >
                Scheduled Review Notices
              </DropdownItem>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/Reviews"}
              >
                Online Review
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      );
    }
  };

  const getAdminOptions = () => {
    if (user !== undefined && isAuthenticated && user.isAdmin) {
      return (
        <>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Admin
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/Forms/ContactFormsOwners"}
                title="Contact Form Owners"
              >
                Contact Form Owners
              </DropdownItem>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/Forms/ScheduledReviewsReport"}
                target="_blank"
                rel="noopener noreferrer"
                title="Forms Scheduled Reviews Report - Opens in new window"
              >
                Forms Scheduled Reviews Report
              </DropdownItem>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/Procedures/MasterDataReport"}
                target="_blank"
                rel="noopener noreferrer"
                title="Procedural Master Data Report - Opens in new window"
              >
                Procedural Master Data Report
              </DropdownItem>
              {getTestEnvironmentOptions()}
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      );
    } else {
      return null;
    }
  };

  const getFeatureOptions = () => {
    if (isAuthenticated) {
      return (
        <>
          {getUnAuthenticatedOptions()}
          {getAuthenticatedOptions()}
          {getAdminOptions()}
          {getHelpContactUsOptions()}
        </>
      );
    } else {
      return (
        <>
          {getUnAuthenticatedOptions()}
          {getHelpContactUsOptions()}
        </>
      );
    }
  };

  const getTestEnvironmentOptions = () => {
    if (fdot.process.env.APP_ENVIRONMENT !== "production") {
      return (
        <>
          <DropdownItem
            style={dropDownMenuItemStyle}
            tag={Link}
            to={"/Impersonate"}
            title="Impersonation"
          >
            Impersonation
          </DropdownItem>
        </>
      );
    }
    return null;
  };

  const getNavbar = () => {
    return (
      <div>
        <Navbar color="dark" dark expand="md">
          <NavbarBrand href="/" style={brandStyle}>
            <span title="Procedural Document Library">PDL</span>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isExpanded} navbar>
            <Nav navbar>{getFeatureOptions()}</Nav>
            <Nav className="ml-auto">
              <NavItem>{getLoggedInUserDisplay()}</NavItem>
              <NavItem>{getLoginOrSignOutLink()}</NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  };

  return getNavbar();
};

export default Header;
