import React, { useEffect, Suspense, CSSProperties } from "react";
import { fetchConfig } from "./redux/slices/configSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./redux/rootReducer";
import { LoadingImage } from "./components/common/loadingImage";
import { fetchUser } from "./redux/slices/userSlice";
import Loading from "react-loading";
import { Switch, Route } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import { initializeApp } from "./utils/initializer";

//const Home = React.lazy(() => import("./components/home"));
const LandingPage = React.lazy(() => import("./components/landingPage"));
const NotAuthorized = React.lazy(() => import("./components/notAuthorized"));
const NoMatch = React.lazy(() => import("./components/noMatch"));
const Staff = React.lazy(() => import("./components/staff/staff"));
const Impersonate = React.lazy(() => import("./components/impersonate"));
const ContactUs = React.lazy(() => import("./components/contactUs"));
const Forms = React.lazy(() => import("./components/forms/forms"));
const Procedures = React.lazy(() =>
  import("./components/procedures/procedures")
);

const FormAddEditForm = React.lazy(() =>
  import("./components/forms/formAddEditForm")
);
const ProcedureAddEdit = React.lazy(() =>
  import("./components/procedures/addEditProcedure")
);
const VersionAddEdit = React.lazy(() =>
  import("./components/procedures/addEditVersion")
);
const FormQuestionComment = React.lazy(() =>
  import("./components/forms/formQuestionComment")
);
const ContactFormOwners = React.lazy(() =>
  import("./components/forms/contactFormOwners")
);
const ConsolidatedForm = React.lazy(() =>
  import("./components/forms/consolidatedForm")
);
const Comments = React.lazy(() => import("./components/forms/comments"));
const FormsScheduledReviewsReport = React.lazy(() =>
  import("./components/forms/scheduledReviewsReport")
);
const ProceduralMasterDataReport = React.lazy(() =>
  import("./components/procedures/masterDataReport")
);

const Reviews = React.lazy(() => import("./components/reviews/reviewTabs"));
const ScheduledReviewMonths = React.lazy(() =>
  import("./components/reviews/scheduledReviewMonths")
);

const ScheduledReviewNotices = React.lazy(() =>
  import("./components/reviews/scheduledReviewNotices")
);
const OnlineReview = React.lazy(() =>
  import("./components/reviews/addEditReview")
);
const ReviewComments = React.lazy(() =>
  import("./components/reviews/comments")
);

const hiddenButScreenReaderAccessibleStyle: CSSProperties = {
  position: "absolute",
  left: "-10000px",
  top: "auto",
  width: "1px",
  height: "1px",
  overflow: "hidden",
};

const Routes: React.FC = () => {
  const dispatch = useDispatch();

  const { loadingConfig, config } = useSelector(
    (state: RootState) => state.configReducer
  );

  const { isUserLoaded, isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchConfig());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (config && user) {
      initializeApp(config, user.displayName);
    }
  }, [config, user]);

  if (!isUserLoaded || loadingConfig) {
    return <LoadingImage></LoadingImage>;
  }

  const getMainLandingPage = (): JSX.Element => {
    if (isAuthenticated) {
      //return <Route exact path="/" component={Home} />;
      return <Route exact path="/" component={Forms} />;
    } else {
      return <Route exact path="/" component={LandingPage} />;
    }
  };

  const getAdminRoutes = (): JSX.Element[] => {
    if (user !== undefined && isAuthenticated && user.isAdmin) {
      const routes: JSX.Element[] = [];

      routes.push(
        <Route
          path="/Forms/Create"
          key="Form"
          exact
          component={FormAddEditForm}
        />
      );

      routes.push(
        <Route
          path="/Forms/edit/:id"
          key="Edit"
          exact
          component={FormAddEditForm}
        />
      );

      routes.push(
        <Route
          path="/Procedures/Create"
          key="Procedure"
          exact
          component={ProcedureAddEdit}
        />
      );

      routes.push(
        <Route
          path="/Procedures/edit/:id"
          key="Edit"
          exact
          component={ProcedureAddEdit}
        />
      );

      routes.push(
        <Route
          path="/Version/edit/:id/:procedureId"
          key="Edit"
          exact
          component={VersionAddEdit}
        ></Route>
      );
      routes.push(
        <Route
          path="/Version/Add/:procedureId"
          key="Add"
          exact
          component={VersionAddEdit}
        ></Route>
      );

      routes.push(
        <Route
          path="/Forms/ContactFormsOwners"
          key="ContactFormsOwners"
          exact
          component={ContactFormOwners}
        />
      );

      routes.push(
        <Route
          path="/Forms/Comments/:id"
          key="FormComments"
          exact
          component={Comments}
        />
      );

      routes.push(
        <Route
          path="/Forms/ScheduledReviewsReport"
          key="ScheduledReviewsReport"
          exact
          component={FormsScheduledReviewsReport}
        />
      );

      routes.push(
        <Route
          path="/Procedures/MasterDataReport"
          key="MasterDataReport"
          exact
          component={ProceduralMasterDataReport}
        />
      );

      return routes;
    } else {
      return [];
    }
  };

  const getAuthenticatedRoutes = (): JSX.Element[] => {
    if (!isAuthenticated) return [];
    const routes: Array<JSX.Element> = [];
    routes.push(<Route path="/Staff" key="Staff" exact component={Staff} />);
    routes.push(
      <Route
        path="/Impersonate"
        key="Impersonate"
        exact
        component={Impersonate}
      />
    );

    routes.push(
      <Route
        path="/Forms/View/:id"
        key="View"
        exact
        component={FormAddEditForm}
      />
    );

    routes.push(
      <Route
        path="/Reviews/scheduledReviewMonths"
        key="scheduledReviewMonths"
        exact
        component={ScheduledReviewMonths}
      />
    );

    routes.push(
      <Route
        path="/Reviews/scheduledReviewNotices/:month/:year"
        key="ScheduledReviewNotices"
        exact
        component={ScheduledReviewNotices}
      />
    );

    routes.push(
      <Route path="/Reviews" key="Reviews" exact component={Reviews} />
    );

    routes.push(
      <Route
        path="/Reviews/Create"
        key="CreateReview"
        exact
        component={OnlineReview}
      />
    );

    routes.push(
      <Route
        path="/Reviews/edit/:id"
        key="EditReview"
        exact
        component={OnlineReview}
      />
    );

    routes.push(
      <Route
        path="/Reviews/comments/:id"
        key="ReviewComment"
        exact
        component={ReviewComments}
      />
    );

    return routes;
  };

  const getUnAuthenticatedRoutes = () => {
    const routes: Array<JSX.Element> = [];
    routes.push(<Route path="/Forms" key="Forms" exact component={Forms} />);
    routes.push(
      <Route path="/Procedures" key="Procedures" exact component={Procedures} />
    );
    routes.push(
      <Route path="/ContactUs" key="ContactUs" exact component={ContactUs} />
    );
    routes.push(
      <Route
        path="/Form/QuestionComment/:id"
        key="FormComment"
        exact
        component={FormQuestionComment}
      />
    );
    routes.push(
      <Route
        path="/Forms/ConsolidatedForm/:id"
        key="ConsolidatedForm"
        exact
        component={ConsolidatedForm}
      />
    );
    return routes;
  };

  return (
    <>
      <a href="#maincontent" style={hiddenButScreenReaderAccessibleStyle}>
        Skip to main content
      </a>
      <Header></Header>
      <div id="maincontent" style={{ paddingBottom: "200px" }}>
        <div className="container-fluid">
          <div className="row">
            <div role="main" className="col">
              <Suspense fallback={<Loading></Loading>}>
                <Switch>
                  {getMainLandingPage()}
                  <Route
                    path="/NotAuthorized"
                    component={NotAuthorized}
                  ></Route>
                  {getUnAuthenticatedRoutes()}
                  {getAuthenticatedRoutes()}
                  {getAdminRoutes()}
                  <Route component={NoMatch} />
                </Switch>
              </Suspense>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Routes;
