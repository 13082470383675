"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var arculus_staff_service_1 = require("@fdot/arculus-staff-service");
var title_case_1 = require("title-case");
exports.staffMap = function (staff) {
    return {
        srsId: staff.id,
        emailAddress: staff.emailAddress,
        azureAdOid: staff.azureAdOid,
        firstName: staff.firstName,
        lastName: staff.lastName,
        racfId: staff.racfId,
        phoneNumber: arculus_staff_service_1.phoneNumberUtils.formatPhoneNumber(staff.phone, staff.phoneExtension),
        positionWorkTitle: staff.positionWorkTitle !== undefined && staff.positionWorkTitle !== null
            ? title_case_1.titleCase(staff.positionWorkTitle)
            : ''
    };
};
